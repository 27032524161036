<template>

<ul class="timeline block">
    <li
        v-for="(activity, index) in history"
        :key="index"
        :class="activity.class"
    >
        <p>{{activity.description}}</p>
        <p v-if="activity.period" class="is-size-7 has-text-grey">
            <template v-if="deliveryEstimate.start && deliveryEstimate.end">
                <template v-if="new Date(deliveryEstimate.start).toDateString() == new Date(deliveryEstimate.end).toDateString()">
                    {{ deliveryEstimate.start|humanizeTimestamp('day') }}
                    tussen
                    {{ deliveryEstimate.start|humanizeTimestamp('time') }}
                    en
                    {{ deliveryEstimate.end|humanizeTimestamp('time') }}
                </template>
                <template v-else>
                    tussen
                    {{ deliveryEstimate.start|humanizeTimestamp }}
                    en
                    {{ deliveryEstimate.end|humanizeTimestamp }}
                </template>
            </template>
            <template v-else-if="deliveryEstimate.start">
                {{ deliveryEstimate.start|humanizeTimestamp('day') }}
                vanaf
                {{ deliveryEstimate.start|humanizeTimestamp('time') }}
            </template>
            <template v-else-if="deliveryEstimate.end">
                {{ deliveryEstimate.end|humanizeTimestamp('day') }}
                voor
                {{ deliveryEstimate.end|humanizeTimestamp('time') }}
            </template>
        </p>
        <p v-else class="is-size-7 has-text-grey">
            {{ activity.last_updated|humanizeTimestamp }}
        </p>
    </li>
</ul>


</template>



<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState({
            label: state => state.label.label
        }),       
        history() {
            let history = this.label.tracking_data?.status_breadcrumbs?.slice() || [];
            this.label.tracking_mail_history?.forEach(element => {
                const description = this.translateMailStatuses(element.mail_status) 
                if (description) {
                    history.push({
                        description: description,
                        last_updated: element.timestamp,
                    })
                }
                
            });
            if (this.label.ticket) {
                history.push({
                    description: 'Ticket aangemaakt',
                    last_updated: this.label.ticket.created_at,
                });
            }
            
            history.push({
                description: 'Laatste update',
                last_updated: this.label.updated_at,
                class: 'is-future',
            });
            history.push({
                description: 'Label gemaakt in Innosend',
                last_updated: this.label.created_at,
            });
            if (this.label.order_date)
                history.push({
                    description: 'Bestelling geplaatst',
                    last_updated: this.label.order_date,
                });
            history.sort((a,b) => new Date(b.last_updated) - new Date(a.last_updated));
            return history
        },
    },
    methods: {
        translateMailStatuses(status) {
            const mapping = {
                printed: 'Bestelling-verwerkt-mail verzonden',
                depot: 'Bestelling-ontvangen-mail verzonden',
                courier: 'Bezorger-onderweg-mail verzonden',
                delivered: 'Bestelling-bezorgd-mail verzonden',
                delivered_servicepoint: 'Bestelling-afgeleverd-servicepunt-mail verzonden',
                return_from_delivery: 'Foutmelding-bestelling-mail verzonden' // does this one even make sense?
            }
            return mapping[status]
        },
    }
}

</script>