<template>
    <article class="section">
        <div>
            <header class="block">
                <div class="level">
                    <div class="level-left">
                        <h1 class="title">Tickets</h1>
                    </div>
                </div>
            </header>
            <section>
                <div class="kanban-board">
                    <div class="kanban-column" v-for="column in phases" :key="column.title">
                    <div class="kanban-column-header">
                        {{ column.title }}
                    </div>
                    <draggable 
                            class="kanban-cards" 
                            :list="column.tickets" 
                            :group="{ name: 'tickets', put: true }" 
                            :options="dragOptions" 
                            @end="handleDrop($event)" 
                        >
                        <div v-for="ticket in column.tickets" :key="ticket.id" :id="ticket.id" :name="column.title">
                            <ticket-canban-card :ticket="ticket" :phases="phases"></ticket-canban-card>
                        </div>
                    </draggable>
                    </div>
                </div>
            </section>

        </div>
    </article>
</template>

<script>
import draggable from 'vuedraggable'
import TicketCanbanCard from '../components/TicketKanbanCard.vue'

export default {
    components: {draggable, TicketCanbanCard},
    data() {
        return {
            dragOptions: {
                group: 'kanban',
                handle: '.kanban-card'
            },
            isModalVisible: false,
            phases: [],
            ticketInModal: null,
        }
    },
    methods: {
        async updatePhase(phase, labelId) {
            const updates = {
                label_id: labelId,
                phase: phase
            }
            const result = await this.$store.dispatch('label/updateTicketPhase', updates)
            if (result.status === 'success') {
                this.$buefy.toast.open({
                    message: 'Fase aangepast',
                    type: 'is-success',
                    duration: 5000,
                });
            }
        },
        async handleDrop(event) {
            const toColumnName = event.to.parentElement.querySelector('.kanban-column-header').textContent.trim();
            const ticketId = parseInt(event.item.id)
            const ticket = this.$store.getters['ticket/ticketById'](ticketId);
            await this.updatePhase(toColumnName, ticket.label.id)
        },
        
    },
    watch: {
            
    },
    async created() {
        const result = await this.$store.dispatch('ticket/getTickets')

        this.phases.push({
            title: 'Onderzoek ingediend',
            tickets: result.tickets.filter(o => o.ticket_extra_details.phase === 'Onderzoek ingediend')
        })
        this.phases.push({
            title: 'Onderzoek opgestart',
            tickets: result.tickets.filter(o => o.ticket_extra_details.phase === 'Onderzoek opgestart')
        })
        this.phases.push({
            title: 'Onderzoek goedgekeurd door vervoerder',
            tickets: result.tickets.filter(o => o.ticket_extra_details.phase === 'Onderzoek goedgekeurd door vervoerder')
        })
        this.phases.push({
            title: 'Onderzoek afgekeurd door vervoerder',
            tickets: result.tickets.filter(o => o.ticket_extra_details.phase === 'Onderzoek afgekeurd door vervoerder')
        })
        this.phases.push({
            title: 'Aanvullende gegevens vereist',
            tickets: result.tickets.filter(o => o.ticket_extra_details.phase === 'Aanvullende gegevens vereist')
        })
        this.phases.push({
            title: 'Onderzoek in behandeling door vervoerder',
            tickets: result.tickets.filter(o => o.ticket_extra_details.phase === 'Onderzoek in behandeling door vervoerder')
        })
        this.phases.push({
            title: 'Pakket komt in aanmerking voor compensatie',
            tickets:result.tickets.filter(o => o.ticket_extra_details.phase === 'Pakket komt in aanmerking voor compensatie')
        })
        this.phases.push({
            title: 'Onderzoeksresultaat bekend',
            tickets: result.tickets.filter(o => o.ticket_extra_details.phase === 'Onderzoeksresultaat bekend')
        })
        this.phases.push({
            title: 'Pakket wordt vergoed',
            tickets: result.tickets.filter(o => o.ticket_extra_details.phase === 'Pakket wordt vergoed')
        })
        this.phases.push({
            title: 'Onderzoek gesloten',
            tickets: result.tickets.filter(o => o.ticket_extra_details.phase === 'Onderzoek gesloten')
        })
    }

}
</script>