<template>
  <div class="modal">
    <div class="card-modal">
      <div class="card-modal-header">
        <h3>Casenummer #{{ ticket.id }}</h3>
        <button class="close-btn" @click="closeModal">Close</button>
      </div>
      <div class="card-modal-content">
        <div class="columns">
          <div v-if="label.ticket" class="column is-4">
              <div class="properties">
                <div class="property">
                    <div class="name">Aankoopprijs</div>
                    <div class="value">{{ label.ticket.cost_price}}</div>
                </div>
                <div class="property">
                    <div class="name">Externe eigenschappen</div>
                    <div class="value">{{ label.ticket.external_properties}}</div>
                </div>
                <div class="property">
                    <div class="name">Interne eigenschappen</div>
                    <div class="value">{{ label.ticket.internal_properties}}</div>
                </div>   
                <div class="property">
                    <div class="name">Organisatie</div>
                    <div class="value">{{ label.ticket.organisation_name}}</div>
                </div>  
                <div class="property">
                    <div class="name">Labelprijs</div>
                    <div class="value">{{ labelPrice}}</div>
                </div>                                 
              </div>
            </div>
          <div class="column is-4">
            <div class="properties">
              <div class="property">
                  <p class="name"> Trackingnummer</p>
                  <p class="value">
                      <a :href="label.tracking_url" target="_blank" rel="noopener noreferrer">
                          <template v-if="label.tracking_number">
                              {{ label.tracking_number }}
                          </template>
                          <template v-else>
                              Trackingpagina van de vervoerder
                          </template>
                      </a>
                  </p>
              </div>
              
              <div class="property">
                  <div class="name">Vervoerder</div>
                  <div class="value">{{ label.service_class }}</div>
              </div>
              <div v-if="label.company_name" class="property">
                  <div class="name">Verzendmethode</div>
                  <div class="value">{{ label.shipment_type }}</div>
              </div>
                <div class="property">
                  <div class="name">Naam</div>
                  <div class="value">{{ label.name }}</div>
              </div>
              <div v-if="label.company_name" class="property">
                  <div class="name">Bedrijf</div>
                  <div class="value">{{ label.company_name }}</div>
              </div>
              <div class="property">
                  <div class="name">Adres</div>
                  <div class="value">
                      <address>
                          {{ label.address_1 }} {{ label.housenumber }}<br>
                          <template v-if="label.address_2">{{ label.address_2 }}<br></template>
                          {{ label.zipcode }} {{ label.city }}<br>
                          <country v-model="label.country"/>
                      </address>
                  </div>
              </div>

              <div v-if="label.email" class="property">
                  <div class="name">E-mail</div>
                  <div class="value">
                      <a :href="`mailto:${label.email}`">{{ label.email }}</a>
                  </div>
              </div>
              <div v-if="label.phone" class="property">
                  <div class="name">Telefoonnummer</div>
                  <div class="value">{{ label.phone }}</div>
              </div>
            </div>
          </div>
          <div class="column is-4">
            <timeline/>
          </div>
        </div>
        <div class="columns">
          <div class="column is-2" v-for="attachment in attachments" :key="attachment.id">
             <a 
                :href="getFileUrl(attachment)"
                :download="attachment.name"
                target="_blank"
                class="attachment"
            >
                <figure v-if="attachment.contentType.startsWith('image')" class="image">
                    <img :src="getFileUrl(attachment)" :alt="attachment.name">
                </figure>
                <figure v-else-if="attachment.contentType === 'application/pdf'" class="file">
                    <b-icon size="is-large" icon="file-document"></b-icon>
                    <figcaption>{{ attachment.name }}</figcaption>
                </figure>
                <figure v-else class="file">
                    <b-icon size="is-large" icon="file"></b-icon>
                    <figcaption>{{ attachment.name }}</figcaption>
                </figure>
            </a>
          </div>
        </div>  
      </div>
    </div>
  </div>
</template>

<script>
import Timeline from './Timeline'
import Country from '@/components/Country';
import {mapState} from 'vuex';


export default {
  components:{Timeline, Country},
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    label: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
        attachments: state => state.ticket.attachments
      }),
    labelPrice(){
      if (this.label?.invoice_items) {
          for (const element of this.label.invoice_items) {
              if (element.type === 'label_price') {
                  return element.parts[0].unit_price
              }
          }
      } 
      return 'Niet bekend'
    },
  },
  data() {
    return {
      isModalVisible: false,      
    };
  },
  methods: {
    closeModal() {
      this.$emit('hideModal')
    },
    getFileUrl(file) {
        return process.env.VUE_APP_UVDESK_BASE_URL + file.path;
    },
  },
  async created() {
    const attachmentsResult = await this.$store.dispatch('ticket/getAttachmentByTicketId', this.ticket.id)
    this.attachments = attachmentsResult.attachments
  }

};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.card-modal {
  width: 50%;
  max-height: 80vh;
  overflow-y: auto;
  background-color: white;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 1.5rem;
  font-weight: bold;
  
}

.close-btn {
  margin-left: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  border: none;
  background-color: #a5a5a5;
  color: white;
  cursor: pointer;
}

.card-modal-content {
  margin-top: 1rem;
  width: 100%;
}
</style>