<template>
    <div>   
        <div :class="kanbanCardClass">
            <div @click="showModal(ticket)">
                <div class="kanban-card-title">Casenummer #{{ ticket.id }} </div>
                <div class="properties">
                    <div class="property">
                        <div class="name">Ordernummer</div>
                        <div class="value">{{ ticket.label.order_number}}</div>
                    </div>
                    <div class="property">
                        <div class="name">Trackingnummer</div>
                        <div class="value">{{ ticket.label.tracking_number}}</div>
                    </div>
                    <div class="property">
                        <div class="name">Klant</div>
                        <div class="value">{{ ticket.ticket_extra_details.organisation_name}}</div>
                    </div>
                    <div class="property">
                        <div class="name">Ticket aangemaakt</div>
                        <div class="value">{{ ticket.created_at}}</div>
                    </div>
                </div>
            </div>
            <div>
                <b-dropdown aria-role="list">
                    <template #trigger="{ active }">
                        <b-button
                            label="Wijzig status"
                            type="is-primary"
                            :icon-right="active ? 'menu-up' : 'menu-down'" />
                    </template>
                    <b-dropdown-item v-for="phase in phaseNames" :key="phase" @click="updatePhase(phase, ticket.label.id)" aria-role="listitem">{{phase}}</b-dropdown-item>
                </b-dropdown>
            </div>
        </div>
        <ticket-modal v-if="isModalVisible === true" :label="ticket.label" :ticket="ticketInModal" @hideModal="hideModal"/>
    </div>
</template>

<script>
import TicketModal from './TicketModal.vue'

export default {
components: { TicketModal},
    
    props: {
        ticket: {
            type: Object,
            required: true,
        },
        phases: {
            type: Array,
            required: true,
        },
    },
    computed: {
        phaseNames() {
            return this.phases.map(phase => phase.title)
        }
    },

    data() {
        return {
            label: {

            },
            isModalVisible: false,
            ticketInModal: {},
            kanbanCardClass: "kanban-card",
        }
    },
    methods: {
        showModal(ticket) {
            this.isModalVisible = true;
            this.ticketInModal = ticket;
        },
        hideModal() {
            this.isModalVisible = false;
            this.ticketInModal = null;
        },
        async updatePhase(phase, labelId) {
            const updates = {
                label_id: labelId,
                phase: phase
            }
            const result = await this.$store.dispatch('label/updateTicketPhase', updates)
            if (result.status === 'success') {
                this.$buefy.toast.open({
                    message: 'Fase aangepast',
                    type: 'is-success',
                    duration: 5000,
                });
                this.kanbanCardClass = "kanban-card is-status-changed"
            }
        },
    }
}
</script>

